<template>
  <div class="mm-subscription">
    <Header class="mm-subscription__header" />

    <div class="mm-subscription__content">
      <slot />
    </div>
  </div>
</template>

<script lang="ts" setup>
import Header from 'components/layouts/subscription/Header.vue';
import { useMainTitle } from 'composables/useMainTitle';

useMainTitle();
</script>

<style lang="scss" scoped>
.mm-subscription {
  height: 100%;
  width: 100%;
  display: grid;
  grid-template-rows: max-content 1fr;
  justify-items: center;

  &__header {
    max-width: 1600px;
  }

  &__content {
    width: 100%;
  }
}

@media screen and (max-width: 1599px) {
  .mm-subscription {
    &__header {
      max-width: 1280px;
    }
  }
}
</style>
